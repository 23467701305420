/** @jsx jsx **/
import { graphql, useStaticQuery } from 'gatsby'
import { BaseStyles, Box, Container, Grid, Text, jsx } from 'theme-ui'
import { CollectionType, GatsbyImageType } from '~/@types/models'
import MarkdownIt from '~/components/MarkdownIt'
import HeroImage from '~/components/hero-image'
import Layout from '~/components/layout'
import AntiqueRugsProductItem from '~/components/product-item-antique-rugs'
import theme, { space } from '~/gatsby-plugin-theme-ui'

type QueryResult = {
  pageData: {
    frontmatter: {
      header: {
        title: string
        description: string
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }
      secondaryImage?: GatsbyImageType
      relatedCollection: CollectionType
      contactTitle: string
      contactLA: Array<{
        method: string
        value: string
      }>
      contactNY: Array<{
        method: string
        value: string
      }>
    }
    html: string
  }
}

const AntiqueRugsPage = () => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "antiqueRugs" } }) {
        frontmatter {
          headerVariant
          header {
            title
            description
            image {
              ...heroImage
            }
            imageMobile {
              ...mobileHeroImage
            }
          }
          secondaryImage {
            ...heroImage
          }
          relatedCollection {
            products {
              ...productCollectionItem
            }
          }
          contactTitle
          contactLA {
            method
            value
          }
          contactNY {
            method
            value
          }
        }
        html
      }
    }
  `)

  const {
    frontmatter: { header, relatedCollection },
    html: content,
  } = query.pageData

  const hasRelatedProducts =
    relatedCollection && relatedCollection.products && relatedCollection.products.length > 0
  return (
    <Layout title={header.title} description={header.description}>
      <HeroImage
        images={{
          default: header.image,
          mobile: header.imageMobile,
        }}
      >
        <Box
          sx={{
            color: 'main',
          }}
        >
          <Container
            sx={{
              p: 0,
              maxWidth: 560,
            }}
          >
            <Text
              sx={{
                display: ['none', null, 'block'],
              }}
              variant="regular"
            >
              {header.description}
            </Text>
          </Container>
        </Box>
      </HeroImage>
      <Box
        as="section"
        sx={{
          my: [theme.space.l, null, theme.space.xl2],
        }}
      >
        <Container
          as="section"
          sx={{
            px: [40, 60, 100, 230],
            my: [50, null, 100],
          }}
        >
          <BaseStyles>
            <MarkdownIt
              content={content}
              contentSx={{
                h2: {
                  textAlign: 'center',
                  fontSize: '32px',
                  fontWeight: 400,
                },
                p: {
                  fontSize: '16px',
                  textAlign: 'center',
                  display: ['none', 'block', null],
                },
              }}
            />
          </BaseStyles>
        </Container>
      </Box>
      <Container>
        {hasRelatedProducts && (
          <Grid variant="layout.template-inStock">
            {relatedCollection.products.map(item => {
              return (
                <Box key={item.handle} mb={space.md2}>
                  <AntiqueRugsProductItem product={item} imageSize="" />
                </Box>
              )
            })}
          </Grid>
        )}
      </Container>
    </Layout>
  )
}

export default AntiqueRugsPage
